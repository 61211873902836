<template>
    <div class="page-invte">
        <van-nav-bar :border="false" :fixed="true" :placeholder="true" :title="$t('邀请朋友')"
                     style="color: #FFFFFF !important;"
                     left-arrow
                     @click-left="onClickLeft"
                     @click-right="onClickRight" >
                     <!-- :right-text="$t('代理模式')" -->
        </van-nav-bar>
        <div class="wrapper">
            <div class="invte-text">
                <div class="t">{{$t('邀请好友 共同赚币')}}</div>
                <div class="t2">{{$t('返佣升级提示')}}</div>
            </div>
            <div class="share-card">
                <div class="share-row">
                    <div class="wx-pic" ref="shareQrcode" id="shareQrcode">
<!--                        <img src="../../assets/images/wx.png">-->
                    </div>
                    <div class="invte-code">{{$t('邀请码')}}：{{share_code}}</div>
                    <van-button class="btn-copy clipboardBtn" type="primary" 
                                data-clipboard-action="copy"
                                :data-clipboard-text="share_code" @click="copy('.clipboardBtn')">{{$t('复制')}}
                    </van-button>
                </div>
                <div class="share-row">
                    <div class="text text-gray">{{$t('推荐链接')}}</div>
                    <div class="link-v">{{share_url}}</div>
                    <van-button class="btn-copy clipboardBtnUrl" type="primary" 
                                data-clipboard-action="copy"
                                :data-clipboard-text="share_url" @click="copy('.clipboardBtnUrl')">{{$t('复制')}}
                    </van-button>
                    
                </div>
                <!-- <div class="share-cell">
                    <div class="col">
                        <a target="_blank"
                           :href="`https://t.me/${$t('telegram_link')}`">
                            <img src="../../assets/pictures/telegram.png" >
                            <div class="txt">{{$t('Telegram')}}</div>
                        </a>
                    </div>
                    <div class="col">
                        <a target="_blank"
                           :href="`sms:&body=${$t('Here is an opportunity to make money through e-commerce. See details here')}:${share_url}`">
                            <img src="../../assets/pictures/sms.png" >
                            <div class="txt">{{$t('SMS')}}</div>
                        </a>
                    </div>
                    <div class="col">
                        <a target="_blank"
                           :href="`mailto:?subject=${$t('asked you to make money')}&body=${$t('Here is a great opportunity to make money online')}:${share_url}`">
                            <img src="../../assets/pictures/youjian.png" >
                        <div class="txt">{{$t('Email')}}</div>
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
        <!--        <Foot :active="2"/>-->
    </div>
</template>
<script>
    import Foot from "@/components/Foot.vue";
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";

    export default {
        components: {
            // Foot
        },
        data() {
            return {
                share_code: '',
                share_url: '',
                show: false,
                posterImg: '',
                qecode: '',
                loading: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onClickRight() {
                this.$router.push({path: '/proxy'})
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            genQrcode() {
                let self = this;
                if (!this.share_url) {
                    return;
                }
                let url = this.share_url + "&t=" + new Date().getTime();
                if (this.qecode) {
                    this.qecode.clear();
                    this.qecode.makeCode(url);
                    this.share_url = url;
                    return;
                }
                this.qecode = new QRCode("shareQrcode", {
                    text: url,
                    typeNumber: 17,
                    width: 128,
                    height: 128,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
        },
        mounted() {
            this.$axios.get(this.$api_url.v_login).then((res) => {
                if (res.data.code == 0) {
                    this.share_code = res.data.data.share_code;
                    this.share_url = res.data.data.share_url;
                    this.genQrcode();
                }
            }).catch((err) => {
                this.$toast.fail(err.message);
            });
        }
    }
</script>
<style>
    .share-row .btn-copy{
        min-width: 120px;
    }
</style>
